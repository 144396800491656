import { axiosCompatible as Ajax } from '@/services/http';

export const user = {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    updateActiveState(_store, model) {
      return Ajax.post('/api/v1/profile/UpdateActiveState', model).then((r) => r.data);
    },
    deleteUser(_store, model) {
      return Ajax.post('/api/v1/security/deleteUser', model).then((r) => r.data);
    },
  },

  mutations: {},
};
