import { toQueryString } from '@/lib/util';
import { axiosCompatible as Ajax } from '@/services/http';

export const ticket = {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    fetchAll(_store, model) {
      return new Promise((resolve, reject) => {
        const obj = Object.assign(
          {
            pageNumber: 0,
            pageSize: 0,
            sorts: [],
          },
          model,
        );

        const qs = toQueryString(obj);
        const url = qs && qs.length ? '/api/v1/tickets?' + qs : '/api/v1/tickets';

        Ajax.get(url)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    fetchSingle(_store, id) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/tickets/' + id)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    create(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/tickets', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    update(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.patch(`/api/v1/tickets/${model.ticket.id}`, model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    delete(_store, id) {
      return new Promise((resolve, reject) => {
        Ajax.delete('/api/v1/tickets/' + id)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    addComment(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/tickets/reply', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setStatus(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.put(`/api/v1/tickets/${model.id}/status`, model.postingData)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  mutations: {},
};
