import { axiosCompatible as Ajax } from '@/services/http';

export const profile = {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    async fetchSingle(_store, userId) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/profile/getUserProfileDetails?id=' + userId)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
  },

  mutations: {},
};
