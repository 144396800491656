import { UserManager, WebStorageStateStore } from 'oidc-client';
import type { UserManagerSettings } from 'oidc-client';

const tokenKey = 'user.token';

export const userManager = new UserManager({
  authority: process.env.NODE_ENV === 'production' ? 'https://oauth.bglogon.com/' : 'https://oauth-stage.arvanik.com/',
  //'https://oauth-stage.arvanik.com/',
  client_id: 'arvanex_vue_client',
  response_type: 'code',
  response_mode: 'query',
  userStore: new WebStorageStateStore({ store: localStorage }),
  scope: 'openid profile roles offline_access Arvanik.Exchange.Panel_api skoruba_identity_admin_api',
  post_logout_redirect_uri: `${window.location.origin}`,
  redirect_uri: `${window.location.origin}/callback.html`,
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
  accessTokenExpiringNotificationTime: 30,
  loadUserInfo: true,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
} as UserManagerSettings);

export function setToken(token?: string) {
  if (!token || token === 'undefined' || token === 'null') return localStorage.removeItem(tokenKey);
  localStorage.setItem(tokenKey, token);
}

export function getStoredToken() {
  const token = localStorage.getItem(tokenKey);
  return token;
}

userManager.events.addUserLoaded(function (user) {
  if (user) setToken(user.access_token);
});
