import { axiosCompatible as Ajax } from '@/services/http';

export const userNotes = {
  namespaced: true,

  state: {
    userNotes: [],
    firstTimeLoaded: false,
  },

  getters: {
    allUserNotes: (state) => state.userNotes,
  },

  actions: {
    async fetch({ commit }, userGuid) {
      return Ajax.post('/api/v1/profile/GetAllNotes', { userGuid }).then((r) => {
        commit('setUserNotes', r.data);
      });
    },
    async create(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/profile/CreateNote', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    async update(_store, model) {
      return new Promise((resolve, reject) => {
        Ajax.post('/api/v1/profile/UpdateNote', model)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    async delete(_store, id) {
      return new Promise((resolve, reject) => {
        Ajax.delete('/api/v1/profile/DeleteNote/' + id)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
    getUserNoteByID(_store, id) {
      return new Promise((resolve, reject) => {
        Ajax.get('/api/v1/profile/GetNote?id=' + id)
          .then((r) => {
            resolve(r.data);
          })
          .catch((e) => reject(e));
      });
    },
  },

  mutations: {
    setFirstTimeLoaded(state) {
      state.firstTimeLoaded = true;
    },
    setUserNotes(state, data) {
      state.userNotes = data;
    },
  },
};
