import 'reflect-metadata';
import vClickOutside from 'v-click-outside';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import '@/assets/styles';
import { i18n, setLocale } from '@/lang';
import '@/lib/native-extensions';
import '@/services/globals';
import { HttpServicePlugin } from '@/services/http';
import { store } from '@/store';
// import { toast } from './lib/toast';
import { router } from './router';
import { IdleJs } from '@/lib/idle-js';
import { isAuth } from '@/services/user';
import { setToken, userManager } from '@/services/oidc';
import { bus } from '@/services/bus';

async function main() {
  Vue.config.productionTip = false;
  Vue.use(HttpServicePlugin);
  Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
  Vue.use(vClickOutside);

  await setLocale(i18n.locale);

  Vue.config.errorHandler = (e) => {
    if (!(Error && (e instanceof Error))) return;
    console.error(e);
    // if (i18n.te(e.name)) toast.error(e.name, e.message);
  };

  new Vue({
    store,
    i18n,
    router,
    render: (h) => h('RouterView'),
  }).$mount('#app');
    
  if (process.env.NODE_ENV !== 'production') return;


  // const [Sentry, { Integrations }] = await Promise.all([
  //   import(/* webpackChunkName: "sentry" */ '@sentry/vue'),
  //   import(/* webpackChunkName: "sentry" */ '@sentry/tracing'),
  // ]);
  // Sentry.init({
  //   Vue,
  //   dsn: 'https://9a7509334d1a474991726902e8ce334f@sentry.parsian-online.com/2',
  //   logErrors: true,
  //   integrations: [new Integrations.BrowserTracing()],
  //   autoSessionTracking: true,
  //   ...{ sendDefaultPii: true },
  // });
}

main().catch(console.error);
