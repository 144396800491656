import mitt, { EventType, Handler } from 'mitt';
import Vue from 'vue';

export const bus = mitt();

export function autoDettachingBus(vm: Vue) {
  const handlers = new Map<Handler, Set<EventType>>();
  const methods = {
    on<T = any>(type: EventType, handler: Handler<T>) {
      bus.on(type, handler);
      if (!handlers.has(handler)) handlers.set(handler, new Set());
      handlers.get(handler).add(type);
      return methods;
    },
  };
  vm.$once('hook:destroyed', () => {
    for (const [handler, types] of handlers) {
      for (const type of types) bus.off(type, handler);
    }
  });
  return methods;
}
