import(/* webpackChunkName: "loader", webpackPreload: true */ './loader.scss');
import './bootstrap/style.scss';
import './mtkeep.css';
import './metronic.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './fonts.scss';
import './theme-custom/index.scss';
import './controls/index.scss';
import './custom.scss';
