import { BvMsgBoxOptions } from 'bootstrap-vue';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import Vue from 'vue';
import { i18n } from '@/lang';

const defaultOptions: SweetAlertOptions = { toast: true, timer: 6000, showConfirmButton: false, position: 'top-start' };
const methods: SweetAlertIcon[] = ['success', 'info', 'warning', 'error'];

type Toast = {
  [k in SweetAlertIcon]: (title: string, text?: string, options?: SweetAlertOptions) => Promise<SweetAlertResult<any>>;
};

export const toast: Toast = Object.fromEntries(
  methods.map((m) => [
    m,
    (
      title: string | Parameters<typeof i18n.t>,
      text?: string | Parameters<typeof i18n.t>,
      options?: SweetAlertOptions,
    ) => {
      [title, text] = [title, text].map((s) => (Array.isArray(s) ? i18n.t(...s) : i18n.t(s)));
      return Swal.fire({ ...defaultOptions, ...options, title, text, icon: m });
    },
  ]),
) as Toast;

export function swalert(title: string, text?: string, options?: SweetAlertOptions) {
  Swal.fire({ confirmButtonColor: '#41b882', cancelButtonColor: '#ff7674', title, text, ...options });
}

export function confirmAlertBox(vm: Vue, description = 'AreYouSure', options?: BvMsgBoxOptions) {
  const confirmDefaultOptions: BvMsgBoxOptions = {
    title: '',
    okTitle: i18n.t('Yes'),
    cancelTitle: i18n.t('Cancel'),
    okVariant: 'success',
    cancelVariant: 'secondary',
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    footerClass: 'p-2',
    hideHeaderClose: true,
    centered: true,
  };
  return vm.$bvModal.msgBoxConfirm(i18n.t(description), { ...confirmDefaultOptions, ...options });
}

export function okAlertBox(vm: Vue, description: string, options?: BvMsgBoxOptions) {
  const confirmDefaultOptions: BvMsgBoxOptions = {
    title: '',
    okTitle: i18n.t('global.ok'),
    centered: true,
    footerClass: 'p-2',
  };
  return vm.$bvModal.msgBoxOk(i18n.t(description), { ...confirmDefaultOptions, ...options });
}
