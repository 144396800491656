import { once } from 'lodash';
import {
  AccountWage,
  CostTypeCreate,
  CountryBrief,
  CountryCreate,
  PhysicalAccountInput,
  ReasonCreate,
  ReasonUpdate,
  TradingUnitBrief,
  CostInvoiceDto,
  PhysicalAccountTradingUnit,
} from '~types/interfaces/api';
import { Paginated, SelectOption } from '~types/interfaces/helper';
import { i18n } from '@/lang';
import { store } from '@/store';
import { httpService } from './http';
import { userManager } from "@/services/oidc";

async function fetchCostTypes(hasAllOption: boolean) {
  const { data } = await httpService.get<Paginated<SelectOption>>('common/GetCostTypes', undefined, { showErr: false });
  return hasAllOption ? [{ id: undefined, name: i18n.t('All') }, ...data] : data;
}

async function fetchCountriesSimple(
  showInactives = false,
  hasAllOption = false,
  includeIds?: number[],
  checkPermission = false,
) {
  const params = { keyword: '', showInactives, checkPermission };
  let { data } = await httpService.get<Paginated<CountryBrief>>('common/GetAllCountries', params, { showErr: false });
  const all = i18n.t('All');
  if (hasAllOption) data = [{ id: undefined, name: all, nationalityName: all }, ...data];
  if (includeIds) {
    data = data.filter((x) => x.id == null || includeIds.includes(x.id));
  }
  return data;
}

async function fetchCities(countryId?: number): Promise<CountryBrief[]> {
  const params = { countryId };
  const { data } = await httpService.get<Paginated<CountryBrief>>('common/GetCities', params, { showErr: false });
  return data;
}

function getAddressByPostalCode(model) {
  return httpService.get('common/GetAddressByPostalCode',model);
}

async function logoutUser() {
  await  httpService.post('Auth/logout');
  localStorage.removeItem("user.token");
  userManager?.signoutRedirect();
}

interface GetOrganizationPhysicalAccounts {
  hasAllOption?: boolean;
  hasExtraInfo?: boolean;
  pageNumber?: number;
  pageSize?: number;
  keyword?: string;
  isActive?: boolean;
  checkUserPermission?: boolean;
  userId?: number;
  calculateRemainingAmounts?: boolean;
}

async function fetchOrganizationPhysicalAccounts(val: GetOrganizationPhysicalAccounts = {}) {
  const {
    hasAllOption = false,
    hasExtraInfo = false,
    pageNumber = 0,
    pageSize = 1000,
    keyword = '',
    isActive = true,
    checkUserPermission = false,
    userId,
    calculateRemainingAmounts = false,
  } = val;
  const params = {
    pageNumber,
    pageSize,
    keyword,
    isActive,
    checkUserPermission,
    userId,
    calculateRemainingAmounts,
  };
  let { data } = await httpService.get('common/GetOrganizationPhysicalAccounts', params, {
    showErr: false,
  });
  if (hasAllOption) data = [{ id: undefined, name: i18n.t('All') }, ...data];
  if (hasExtraInfo) {
    data.forEach((item) => {
      if (item.ownerBranch) item.name += ` | ${i18n.t('Branch')}: ${item.ownerBranch.name}`;
      if (item.defaultTradingUnit) item.name += ` | ${i18n.t('TradingUnit')}: ${item.defaultTradingUnit.name}`;
    });
  }
  return data;
}
function fetchOrganizationPhysicalAccount(guid: string) {
  return httpService.get('common/GetOrganizationPhysicalAccountDetails', { guid });
}

function clearObjectEmptyValues(params) {
  Object.keys(params||{}).forEach(key => {
    if (params[key] === null) { delete params[key]; }
    else if(typeof params[key] === 'object' && !params[key].length) { delete params[key]; }
  });
  
  return params;
}

async function getAllBranchPhysicalAccounts(params, { hasAllOption = false, hasExtraInfo = false }) {
  const data = await httpService.get('common/GetAllBranchPhysicalAccounts', params, {
    showErr: false,
  });
  data.forEach((item) => {
    item.name =
      i18n.locale === 'fa' ? (item.name?.fa && item.name?.fa.length ? item.name.fa : item.name?.en) : item.name?.en;
    if (hasExtraInfo) {
      if (item.ownerBranch)
        item.name += ` | ${i18n.t('Branch')}: ${
          i18n.locale === 'fa' ? item.ownerBranch?.name?.fa : item.ownerBranch?.name?.en
        }`;
      const index = item.tradingUnits.findIndex((x) => x.isDefault);
      if (index !== -1)
        item.name += ` | ${i18n.t('TradingUnit')}: ${
          i18n.locale === 'fa' ? item.tradingUnits[index]?.name?.fa : item.tradingUnits[index]?.name?.en
        }`;
    }
  });
  if (hasAllOption) data.splice(0, 0, { id: null, name: i18n.t('All') });

  return data;
}

async function fetchOrganizationPhysicalAccountDropdown(params, { hasAllOption = false, hasExtraInfo = false }) {
  const data = await httpService.get('common/GetBrancheAllPhysicalAccount', params, {
    showErr: false,
  });
  data.forEach((item) => {
    item.name =
      i18n.locale === 'fa' ? (item.name?.fa && item.name?.fa.length ? item.name.fa : item.name?.en) : item.name?.en;
    if (hasExtraInfo) {
      if (item.ownerBranch)
        item.name += ` | ${i18n.t('Branch')}: ${
          i18n.locale === 'fa' ? item.ownerBranch?.name?.fa : item.ownerBranch?.name?.en
        }`;
      const index = item.tradingUnits.findIndex((x) => x.isDefault);
      if (index !== -1)
        item.name += ` | ${i18n.t('TradingUnit')}: ${
          i18n.locale === 'fa' ? item.tradingUnits[index]?.name?.fa : item.tradingUnits[index]?.name?.en
        }`;
    }
  });
  if (hasAllOption) data.splice(0, 0, { id: null, name: i18n.t('All') });

  return data;
}
function addPhysicalAccountTradingUnit(model: PhysicalAccountTradingUnit) {
  return httpService.post<PhysicalAccountTradingUnit>('Common/AddPhysicalAccountTradingUnit', model, {
    showErr: 'dialog',
  });
}
function setPhysicalAccountTradingUnitDefault(model: PhysicalAccountTradingUnit) {
  return httpService.put<PhysicalAccountTradingUnit>('Common/SetDefaultPhysicalAccountTradingUnit', model, {
    showErr: 'dialog',
  });
}
function deletePhysicalAccountTradingUnit(model: PhysicalAccountTradingUnit) {
  return httpService.delete<PhysicalAccountTradingUnit>('Common/RemovePhysicalAccountTradingUnit', model, {
    showErr: 'dialog',
  });
}

const fetchCurrencyOptions = once(async () => {
  const { data } = await httpService.get<Paginated<TradingUnitBrief>>('common/GetAllTradingUnits');
  return data;
});

const loadCurrencies = once(async () => {
  const data = await fetchCurrencyOptions();
  const currencies = new Map([...store.state.currencies]);
  data.forEach(({ symbol, decimalPlaces, name, enName, ...rest }) => {
    currencies.set(symbol, { maximumFractionDigits: decimalPlaces, name: { en: enName, fa: name }, ...rest });
  });
  store.commit('setCurrencies', currencies);
  return currencies;
});

function deleteReason(id: number) {
  return httpService.delete(`Common/DeleteReason/${id}`, undefined, { showErr: 'dialog' });
}

function saveReason(params: ReasonCreate | ReasonUpdate) {
  return 'id' in params && params.id
    ? httpService.post('Common/UpdateReason', params, { showErr: 'dialog' })
    : httpService.post('Common/CreateReason', params, { showErr: 'dialog' });
}

interface GetReasons {
  hasAllOption?: boolean;
  sorts?: object[];
  pageNumber?: number;
  pageSize?: number;
  keyword?: string;
  referenceId?: string;
  reasonType?: number;
}
async function fetchReasons(val: GetReasons = {}) {
  const { pageNumber = 0, pageSize = 100, referenceId, reasonType, hasAllOption = false } = val;
  const params = { pageNumber, pageSize, referenceId, reasonType };
  const { data } = await httpService.get('common/GetReasons', params);
  return hasAllOption ? [{ id: null, name: i18n.t('All') }, ...data] : data;
}
async function fetchCustomerBankAccounts(customerId: number, includeTradingUnitIds: number[]) {
  const params = {
    isActive: true,
    CustomerId: customerId,
  };
  const { data, extraData } = await httpService.get('common/GetCustomerBankAccounts', params);
  return {
    data:
      includeTradingUnitIds && includeTradingUnitIds.length > 0
        ? data.filter(
            (x) =>
              includeTradingUnitIds.indexOf(x.defaultTradingUnitId) >= 0 ||
              includeTradingUnitIds.filter((value) => x.tradingUnits.indexOf(value) >= 0).length > 0,
          )
        : data,
    extraData,
  };
}
function approvePhysicalAccount(id) {
  return httpService.put(`Common/ApprovePhysicalAccount?id=${id}`, { showErr: 'dialog' });
}
function fetchAllReturnReasons(referenceEntity: string, referenceId: string) {
  return httpService.get('common/GetAllReturnReasons', { referenceId, referenceEntity });
}
function getAllTradingUnitTransactionApproverRoles(params?: any) {
  return httpService.get('common/GetAllTradingUnitTransactionApproverRoles', params);
}
function createTradingUnitTransactionApproverRole(model: any) {
  return httpService.post('common/CreateTradingUnitTransactionApproverRole', model);
}
function updateTradingUnitTransactionApproverRole(model: any) {
  return httpService.put('common/UpdateTradingUnitTransactionApproverRole', model);
}
function deleteTradingUnitTransactionApproverRole(id: string) {
  return httpService.delete(`common/DeleteTradingUnitTransactionApproverRole?id=${id}`);
}

function DeleteTradingUnit(id: number) {
  return httpService.delete(`Common/DeleteTradingUnit/${id}`, undefined, { showErr: 'dialog' });
}
function DeleteTradingUnitReceiptPolicy(id: number) {
  return httpService.delete(`Common/DeleteTradingUnitReceiptPolicy/${id}`, undefined, { showErr: 'dialog' });
}
function fetchTradingUnit(id: number) {
  return httpService.post('Common/GetTradingUnit', { id }, { showErr: 'dialog' });
}
function fetchTradingUnitPolicy(id: number) {
  return httpService.get(`Common/GetTradingUnitReceiptPolicy/${id}`, undefined, { showErr: 'dialog' });
}
function fetchTradingUnitByBranch(branchId: number, tradingUnitType: number) {
  return httpService.get(`Common/GetTradingUnitsByBranchId?BranchId=${branchId}&TradingUnitType=${tradingUnitType}`);
}
async function saveTradingUnit(model: any) {
  const method = model.id ? 'Update' : 'Create';
  const { data } = await httpService
    .post(`Common/${method}TradingUnit`, model, { showErr: 'dialog' })
    .loader('save-trade-unit');
  return data;
}
async function saveTradingUnitReceiptPolicy(model: any, id?: number) {
  const method = id ? 'Update' : 'Create';
  if (id) {
    const { data } = await httpService
      .put(`Common/${method}TradingUnitReceiptPolicy/${id}`, model, { showErr: 'dialog' })
      .loader('save-trade-unit-receipt-policy');
    return data;
  } else {
    const { data } = await httpService
      .post(`Common/${method}TradingUnitReceiptPolicy`, model, { showErr: 'dialog' })
      .loader('save-trade-unit-receipt-policy');
    return data;
  }
}
function deletePhysicalAccount(id: string) {
  return httpService.delete(`Common/DeletePhysicalAccount/${id}`, undefined, { showErr: 'dialog' });
}
function savePhysicalAccount(params: PhysicalAccountInput) {
  const method = 'guid' in params && params.guid ? 'Update' : 'Create';
  return httpService.post(`Common/${method}PhysicalAccount`, params, { showErr: false, timeout: 60000 });
}

function saveCountry(params: CountryCreate) {
  return 'id' in params && params.id
    ? httpService.post('Common/UpdateCountry', params, { showErr: 'dialog' })
    : httpService.post('Common/CreateCountry', params, { showErr: 'dialog' });
}
function DeleteCostType(id: number) {
  return httpService.post('Common/DeleteCostType', { id }, { showErr: 'dialog' });
}
function saveCostType(params: CostTypeCreate) {
  return 'id' in params && params.id
    ? httpService.post('Common/UpdateCostType', params, { showErr: 'dialog' })
    : httpService.post('Common/CreateCostType', params, { showErr: 'dialog' });
}
function fetchAllAccessedEmployeePhysicalAccounts() {
  return httpService.get('Common/GetAllAccessedEmployeePhysicalAccounts');
}

async function checkAccessPettyCashPhysicalAccounts(id: string, branchId: number) {
  const data = await httpService.get(
    `Common/GetAccessedPettyCashPhysicalAccounts?EmployeeId=${id}&BranchId=${branchId}`,
  );
  return data?.map((item) => ({
    ...item,
    name: i18n.locale === 'fa' ? (item.name?.fa && item.name?.fa.length ? item.name.fa : item.name?.en) : item.name?.en,
  }));
}
async function getUserPhysicalAccountRemaining(id: string) {
  const accountRemaining = await httpService.get(`Common/GetUserPhysicalAccountRemaining/${id}`);
  return accountRemaining;
}
async function getPhysicalAccountRemaining(id: number) {
  const accountRemaining = await httpService.get(`Common/GetPhysicalAccountRemaining/${id}`);
  return accountRemaining;
}

function GetPhysicalAccountCost({ id = undefined, guid = undefined }: { id: number; guid: string }) {
  return httpService.get(`Common/GetPhysicalAccountCost`, { id, guid });
}

function createPhysicalAccountCost(payload: AccountWage) {
  return httpService.post('Common/CreatePhysicalAccountCost', payload, { showErr: false });
}

function updatePhysicalAccountCost(payload: AccountWage) {
  return httpService.post('Common/UpdatePhysicalAccountCost', payload, { showErr: false });
}

function rejectPhysicalAccountCost(id: number) {
  return httpService.post(`Common/RejectPhysicalAccountCost/${id}`);
}

function GetPhysicalAccountProfit({ id = undefined, guid = undefined }: { id: number; guid: string }) {
  return httpService.get(`Common/GetPhysicalAccountProfit`, { id, guid });
}

function createPhysicalAccountProfit(payload: AccountWage) {
  return httpService.post('Common/CreatePhysicalAccountProfit', payload, { showErr: false });
}

function updatePhysicalAccountProfit(payload: AccountWage) {
  return httpService.post('Common/UpdatePhysicalAccountProfit', payload, { showErr: false });
}

function rejectPhysicalAccountProfit(id: number) {
  return httpService.post(`Common/RejectPhysicalAccountProfit/${id}`);
}

function createCostInvoice(payload: CostInvoiceDto) {
  return httpService.post('Common/CreateCostInvoice', payload, { showErr: false });
}

function approvePendingCostInvoice(id: number) {
  return httpService.post(`Common/PendingApproveCostInvoice/${id}`);
}

function returnFinalizedCostInvoiceToPending(payload) {
  return httpService.post(`Common/FinalizedReturnToPendingCostInvoice`, payload);
}

function rejectPendingCostInvoice(payload) {
  return httpService.post(`Common/PendingRejectCostInvoice`, payload);
}

async function fetchAllBanks(val) {
  const params = { originCountryId: val.countryId };

  let constServiceData = store.getters["app/constServiceData"];
  let apiKey = `GetAllBanks`;
  if(params.originCountryId){
    apiKey += `/originCountryId:${params.originCountryId}`
  }
  if (constServiceData[apiKey]?.length) {
    return [ ...constServiceData[apiKey]];
  }

  const {data} = await httpService.get('Common/GetAllBanks', params);

    let options = data.map((x) => ({
      name:
        (i18n.locale === 'fa' && x.persianName && x.persianName.length && x.persianName) ||
        (x.englishName && x.englishName.length && x.englishName) ||
        (x.shortName && x.shortName.length && x.shortName),
      englishName: x.englishName,
      id: x.id,
    }));
    if(val.hasAllOption && !val.multiple) {
      options.splice(0, 0, { id: null, name: i18n.t('All') });
    }
    let finalData = [...options];
    Object.assign(constServiceData, { [apiKey]: finalData });
    store.commit("app/setConstServiceData", constServiceData);
    
    return finalData;
} 

export const commonService = {
  createTradingUnitTransactionApproverRole,
  getAllTradingUnitTransactionApproverRoles,
  updateTradingUnitTransactionApproverRole,
  deleteTradingUnitTransactionApproverRole,
  logoutUser,
  getAddressByPostalCode,
  fetchCostTypes,
  DeleteCostType,
  fetchCountriesSimple,
  fetchCities,
  fetchCurrencyOptions,
  loadCurrencies,
  fetchOrganizationPhysicalAccounts,
  fetchOrganizationPhysicalAccount,
  fetchOrganizationPhysicalAccountDropdown,
  deleteReason,
  saveReason,
  fetchReasons,
  fetchCustomerBankAccounts,
  approvePhysicalAccount,
  fetchAllReturnReasons,
  DeleteTradingUnit,
  DeleteTradingUnitReceiptPolicy,
  fetchTradingUnit,
  fetchTradingUnitPolicy,
  fetchTradingUnitByBranch,
  saveTradingUnit,
  saveTradingUnitReceiptPolicy,
  deletePhysicalAccount,
  addPhysicalAccountTradingUnit,
  setPhysicalAccountTradingUnitDefault,
  deletePhysicalAccountTradingUnit,
  saveCountry,
  saveCostType,
  savePhysicalAccount,
  fetchAllAccessedEmployeePhysicalAccounts,
  checkAccessPettyCashPhysicalAccounts,
  getUserPhysicalAccountRemaining,
  getPhysicalAccountRemaining,
  createPhysicalAccountCost,
  updatePhysicalAccountCost,
  createPhysicalAccountProfit,
  updatePhysicalAccountProfit,
  GetPhysicalAccountCost,
  rejectPhysicalAccountCost,
  GetPhysicalAccountProfit,
  rejectPhysicalAccountProfit,
  createCostInvoice,
  approvePendingCostInvoice,
  returnFinalizedCostInvoiceToPending,
  rejectPendingCostInvoice,
  fetchAllBanks,
  getAllBranchPhysicalAccounts
};
